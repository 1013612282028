import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Hot Wheelz', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: 'We’re a commercial kitchen that supplies food and goods to amazing restaurants in Dallas, Texas including The Biscuit Bar', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Hot Wheelz Cookery',
    info: 'Hot Wheelz Cookery is a commercial kitchen serving the best restaurants in Dallas.',
    info2: 'We’re hiring lots of positions. Find your place with us!',
    url: 'https://www.applicantpro.com/openings/hotwheelzcookery/',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'The Biscuit Bar',
    info: 'Biscuits. Tots. Taps.',
    info2: 'We’re hiring all positions and many of our locations. Find your place with us!',
    url: 'https://thebiscuitbar.applicantpro.com/jobs/',
  },
  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: 'The Biscuit Bar',
  //   info: 'We’re hiring all positions and many of our locations. Find your place with us!',
  //   info2: '',
  //   url: 'https://thebiscuitbar.applicantpro.com/jobs/',
  // }
];

// CONTACT DATA
export const contactData = {
  cta: 'Have questions about what we do? Want to work with us? Hit us up!',
  btn: 'Contact us',
  email: 'janie@thebiscuit.bar',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
